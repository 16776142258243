import React from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';

import { FluidImage } from '../Trainings/types';

const Container = styled('div')`
  ${spacing}
`;

interface Props {
  className?: string;
  layout?: string;
  data?: Array<{ imageSharp: FluidImage }>;
}

export default function Image({
  className = '',
  data = [],
  layout = 'row',
}: Props) {
  return (
    <Container className={className} pt={layout === 'column' ? 10 : 0}>
      {data.map(({ imageSharp }, index) => (
        <Img fluid={imageSharp.childImageSharp.fluid} key={index} />
      ))}
    </Container>
  );
}

export const fragmentHomepageImage = graphql`
  fragment HomepageImage on PRISMIC_HomepageBodyImage {
    type
    primary {
      caption
      title
      subtitle
      text
      color_scheme
      layout
    }
    fields {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

// export const fragmentPageImage = graphql`
//   fragment PageImage on PRISMIC_PageBodyImage {
//     type
//     primary {
//       caption
//       title
//       subtitle
//       text
//       color_scheme
//       layout
//     }
//     fields {
//       image
//       imageSharp {
//         childImageSharp {
//           fluid(maxWidth: 1200) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   }
// `;

export const fragmentServiceImage = graphql`
  fragment ServiceImage on PRISMIC_ServiceBodyImage {
    type
    primary {
      caption
      title
      subtitle
      text
      color_scheme
      layout
    }
    fields {
      image
      imageSharp {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

Image.fragments = [fragmentServiceImage];
