import React, { useEffect, createRef, useContext } from 'react';

import { MasonryGridContext } from './MasonryGrid';

interface Props {
  children: React.ReactNode;
}

export default function MasonryCell({ children }: Props) {
  const innerRef = createRef<HTMLDivElement>();
  const contentRef = createRef<HTMLDivElement>();
  const { gridGap, rowHeight } = useContext(MasonryGridContext);

  function handleResize() {
    if (contentRef && contentRef.current && innerRef && innerRef.current) {
      const height = contentRef.current.getBoundingClientRect().height;
      const rowSpan = Math.ceil((height + gridGap) / (rowHeight + gridGap));
      innerRef.current.style.gridRowEnd = 'span ' + rowSpan;
      contentRef.current.style.height = '100%';
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [contentRef, innerRef]);

  return (
    <div ref={innerRef}>
      <div ref={contentRef}>{children}</div>
    </div>
  );
}
