export { default as Sections } from './Sections';
export { default as Section } from './Section';

export { default as Text, fragmentServiceText } from './Text';

export {
  default as IconBox,
  fragmentHomepageIconBox,
  fragmentPageIconBox,
  fragmentServiceIconBox,
} from './IconBox';

export {
  default as CheckList,
  fragmentHomepageCheckList,
  fragmentServiceCheckList,
} from './CheckList';

export {
  default as TestimonialBox,
  fragmentHomepageTestimonialBox,
  fragmentServiceTestimonialBox,
} from './TestimonialBox';

export {
  default as TrainingCards,
  fragmentHomepageTrainingCards,
  fragmentServiceTrainingCards,
} from './TrainingCards';

export {
  default as Image,
  fragmentServiceImage,
  fragmentHomepageImage,
} from './Image';

export { PrismicSection } from './types';
