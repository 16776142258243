import React, { createContext } from 'react';

import styled from 'styled-components';
import { Box } from '@material-ui/core';

const Container = styled(({ rowHeight, gridGap, ...props }) => (
  <Box {...props} />
))`
  width: 100%;
  display: grid;
  grid-gap: ${({ gridGap }) => gridGap}px;
  grid-auto-rows: ${({ rowHeight }) => rowHeight}px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
`;

const rowHeight = 16;
const defaultGridGap = 16;

export const MasonryGridContext = createContext<{
  gridGap: number;
  rowHeight: number;
}>({ gridGap: defaultGridGap, rowHeight });

interface Props {
  children?: React.ReactNode;
  gridGap?: number;
}

export default function MasonryGrid({
  children = [],
  gridGap = defaultGridGap,
  ...props
}: Props) {
  return (
    <MasonryGridContext.Provider
      value={{
        gridGap,
        rowHeight,
      }}
    >
      <Container {...props} gridGap={gridGap} rowHeight={rowHeight}>
        {children}
      </Container>
    </MasonryGridContext.Provider>
  );
}
