import React, { Suspense } from 'react';
// eslint-disable-next-line import/default
import camelCase from 'camelcase';

import { useQuery } from '@apollo/react-hooks';

import styled from 'styled-components';

import {
  compose,
  borders,
  palette,
  display,
  flexbox,
  sizing,
  spacing,
  typography,
  PaletteProps,
} from '@material-ui/system';
import { Typography } from '@material-ui/core';

import RichText from '../RichText';

import { LAYOUT_STATE_QUERY } from '../Layout';

interface Props {
  caption?: string;
  colorScheme?: string;
  divider?: boolean;
  fields?: [];
  layout?: 'column' | 'row' | 'row-reverse';
  subtitle?: string;
  text?: [];
  title?: string;
  sliceType: string;
}

export default function Section({
  caption = '',
  colorScheme = 'light',
  divider = true,
  fields = [],
  layout = 'column',
  subtitle = '',
  text = [],
  title = '',
  sliceType,
}: Props) {
  const { data, loading } = useQuery(LAYOUT_STATE_QUERY);

  if (!data || loading) {
    return <>{'loading section component'}</>;
  }

  const componentName = camelCase(sliceType, {
    pascalCase: true,
  });

  const SliceComponent = React.lazy(() => import(`./${componentName}`));

  return (
    <SectionWrapper
      bgcolor={colorScheme === 'dark' ? 'background.contrast.main' : undefined}
      color={colorScheme === 'dark' ? 'text.inverted' : 'text.primary'}
    >
      <Container
        alignItems="center"
        borderBottom={divider ? '1px solid' : undefined}
        borderColor={
          colorScheme === 'dark' ? 'background.contrast.main' : 'divider'
        }
        display="flex"
        flexDirection={{ xs: 'column', md: layout }}
        maxWidth={data.layout.maxWidth}
        mx="auto"
        px={{ xs: 2, lg: 0 }}
        py={{ xs: 7, sm: 14 }}
      >
        <Item
          flexBasis={{ xs: '100%', md: 'auto' }}
          flexGrow={1}
          flexShrink={1}
          py={{ xs: 2, sm: 0 }}
        >
          {caption && (
            <Caption
              color="inherit"
              display="block"
              pb={2}
              textAlign={{
                xs: 'center',
                md:
                  layout === 'row' || layout === 'row-reverse'
                    ? 'left'
                    : 'center',
              }}
              variant="caption"
            >
              {caption}
            </Caption>
          )}
          <Title
            color="inherit"
            pb={3}
            textAlign={{
              xs: 'center',
              md:
                layout === 'row' || layout === 'row-reverse'
                  ? 'left'
                  : 'center',
            }}
            variant="h2"
          >
            {title}
          </Title>
          <Subtitle
            color="inherit"
            display="block"
            pb={3}
            textAlign={{
              xs: 'center',
              md:
                layout === 'row' || layout === 'row-reverse'
                  ? 'left'
                  : 'center',
            }}
            variant="subtitle1"
          >
            {subtitle}
          </Subtitle>
          <RichText text={text} />
        </Item>
        {SliceComponent && (
          <Item
            flexBasis={{ xs: '100%', md: 'auto' }}
            flexGrow={1}
            flexShrink={1}
            py={{ xs: 2, sm: 0 }}
          >
            <Suspense fallback={<div>{'Loading...'}</div>}>
              <SliceComponent
                colorScheme={colorScheme}
                data={fields}
                layout={layout}
              />
            </Suspense>
          </Item>
        )}
      </Container>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div<PaletteProps>`
  ${palette}
`;

const Container = styled.div`
  ${compose(
    spacing,
    borders,
    display,
    flexbox,
    sizing,
  )}
`;

const Item = styled.div`
  ${compose(
    spacing,
    flexbox,
  )}
  width: 100%;
  box-sizing: border-box;
`;

const Caption = styled(({ textAlign, ...props }) => <Typography {...props} />)`
  ${compose(
    spacing,
    palette,
    typography,
  )}
  text-transform: uppercase;
`;

const Title = styled(({ textAlign, ...props }) => <Typography {...props} />)`
  ${compose(
    spacing,
    palette,
    typography,
  )}
`;

const Subtitle = styled(({ textAlign, ...props }) => <Typography {...props} />)`
  ${compose(
    spacing,
    palette,
    typography,
  )}
`;
