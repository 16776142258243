import React from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';

import RichText from '../RichText';

const Container = styled('div')`
  ${spacing}
`;

interface Props {
  className?: string;
  data: [{ text: string }];
}

export default function Text({ className = '', data }: Props) {
  return (
    <Container className={className} pt={2}>
      {data.map(({ text }, index) => (
        <RichText
          fontSize="body1.fontSize"
          key={index} // eslint-disable-line react/no-array-index-key
          text={text}
        />
      ))}
    </Container>
  );
}

// export const fragmentHomepageText = graphql`
//   fragment HomepageText on PRISMIC_HomepageBodyText {
//     type
//     primary {
//       caption
//       title
//       subtitle
//       text
//       cta_label
//       cta_target
//       layout
//       color_scheme
//     }
//   }
// `;

// export const fragmentPageText = graphql`
//   fragment PageText on PRISMIC_PageBodyText {
//     type
//     primary {
//       caption
//       title
//       subtitle
//       text
//       cta_label
//       cta_target
//       layout
//       color_scheme
//     }
//   }
// `;

export const fragmentServiceText = graphql`
  fragment ServiceText on PRISMIC_ServiceBodyText {
    type
    primary {
      caption
      title
      subtitle
      text
      cta_label
      cta_target
      layout
      color_scheme
    }
  }
`;

Text.fragments = [fragmentServiceText];
