import React from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import { FluidImage } from '../components/Trainings/types';

import {
  Sections,
  // fragmentHomepageText,
  fragmentHomepageImage,
  fragmentHomepageIconBox,
  fragmentHomepageCheckList,
  fragmentHomepageTestimonialBox,
  fragmentHomepageTrainingCards,
  PrismicSection,
} from '../components/Sections';

interface Props {
  data: {
    prismic: {
      allHomepages: {
        edges: Array<{
          node: {
            header_imageSharp: FluidImage;
            header_subtitle: string;
            header_title: string;
            seo_page_title: string;
            body: PrismicSection[];
          };
        }>;
      };
    };
  };
}

export default function Home({ data }: Props) {
  const [{ node }] = data.prismic.allHomepages.edges;

  if (!node) {
    return null;
  }

  return (
    <Layout
      heroImage={node.header_imageSharp}
      subtitle={node.header_subtitle}
      title={node.header_title}
      variant="landing"
    >
      <Seo title={node.seo_page_title} />
      <Sections data={node.body} />
    </Layout>
  );
}

export const query = graphql`
  query getHomepage {
    prismic {
      allHomepages {
        edges {
          node {
            header_caption
            header_title
            header_subtitle
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cta_label
            cta_target
            seo_page_title
            body {
              ...HomepageTrainingCards
              ...HomepageTestimonialBox
              ...HomepageIconBox
              ...HomepageTestimonialBox
              ...HomepageCheckList
              ...HomepageImage
            }
          }
        }
      }
    }
  }
`;

Home.fragments = [
  fragmentHomepageCheckList,
  fragmentHomepageIconBox,
  fragmentHomepageTestimonialBox,
  fragmentHomepageTrainingCards,
  fragmentHomepageImage,
];
