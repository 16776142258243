import React from 'react';

import { graphql } from 'gatsby';

import styled from 'styled-components';

import { TrainingCard } from '../Trainings';
import { Training } from '../Trainings/types';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 24px;
  grid-template-rows: repeat(auto-fit, minmax(480px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

interface Props {
  className?: string;
  data: Array<{ training: Training }>;
}

export default function TrainingCards({ className, data }: Props) {
  return (
    <Container className={className}>
      {data.map(({ training }) => (
        <TrainingCard
          id={training.id}
          key={training.id}
          name={training.name}
          slug={training.slug}
          subtitle={training.subtitle}
        />
      ))}
    </Container>
  );
}

export const fragmentHomepageTrainingCards = graphql`
  fragment HomepageTrainingCards on PRISMIC_HomepageBodyTraining_cards {
    type
    fields {
      training
    }
    primary {
      caption
      title
      subtitle
      color_scheme
    }
  }
`;

export const fragmentServiceTrainingCards = graphql`
  fragment ServiceTrainingCards on PRISMIC_ServiceBodyTraining_cards {
    type
    fields {
      training
    }
    primary {
      caption
      title
      subtitle
      color_scheme
    }
  }
`;

TrainingCards.fragments = [
  fragmentHomepageTrainingCards,
  fragmentServiceTrainingCards,
];
