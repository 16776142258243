import React from 'react';

import { graphql } from 'gatsby';

import styled from 'styled-components';
import {
  compose,
  spacing,
  display,
  palette,
  borders,
  flexbox,
  typography,
  sizing,
  PaletteProps,
  SpacingProps,
  BordersProps,
  DisplayProps,
  FlexboxProps,
  SizingProps,
} from '@material-ui/system';
import { Typography } from '@material-ui/core';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import RichText from '../RichText';
import { MasonryGrid, MasonryCell } from '../Masonry';

interface Props {
  colorScheme?: 'light' | 'dark';
  data?: Array<{
    box_title: string;
    box_content: string;
    box_type: 'plain' | 'border';
    orientation: 'row' | 'column';
  }>;
}

export default function IconBox({ colorScheme = 'light', data = [] }: Props) {
  return (
    <MasonryGrid gridGap={24}>
      {data.map(({ box_title, box_content, box_type, orientation }) => {
        let bgcolor = 'background.lightGrey';

        if (colorScheme === 'dark') {
          bgcolor = 'background.contrast.light';
        } else if (colorScheme === 'light' && box_type === 'border') {
          bgcolor = '#FAFAFA';
        }

        return (
          <MasonryCell key={box_title}>
            <Container
              alignItems={orientation === 'row' ? 'flex-start' : 'center'}
              bgcolor={bgcolor}
              border={box_type === 'border' ? 1 : 0}
              borderColor="divider"
              borderRadius="borderRadius"
              display="flex"
              flexDirection={orientation}
              height="100%"
              p={4}
            >
              <Icon
                pb={orientation === 'row' ? 0 : 4}
                pr={orientation === 'row' ? 4 : 0}
              >
                <AssignmentTurnedInIcon color="inherit" />
              </Icon>
              <div>
                <Typography
                  align={orientation === 'row' ? 'left' : 'center'}
                  color="inherit"
                  variant="subtitle2"
                >
                  {box_title}
                </Typography>
                <Text
                  color="inherit"
                  mt={1}
                  text={box_content}
                  textAlign={orientation === 'row' ? 'left' : 'center'}
                />
              </div>
            </Container>
          </MasonryCell>
        );
      })}
    </MasonryGrid>
  );
}

const Container = styled.div<
  PaletteProps &
    SpacingProps &
    BordersProps &
    DisplayProps &
    FlexboxProps &
    SizingProps
>`
  ${compose(
    palette,
    spacing,
    borders,
    display,
    flexbox,
    sizing,
  )}
`;

const Icon = styled.div`
  ${spacing}
`;

const Text = styled(props => <RichText {...props} />)`
  ${spacing}
  ${typography}
  & p:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const fragmentHomepageIconBox = graphql`
  fragment HomepageIconBox on PRISMIC_HomepageBodyIcon_box {
    type
    primary {
      caption
      title
      subtitle
      text
      color_scheme
      layout
    }
    fields {
      icon_name
      box_title
      box_content
      box_type
      orientation
    }
  }
`;

export const fragmentPageIconBox = graphql`
  fragment PageIconBox on PRISMIC_PageBodyIcon_box {
    type
    primary {
      caption
      title
      subtitle
      text
      color_scheme
      layout
    }
    fields {
      icon_name
      box_title
      box_content
      box_type
      orientation
    }
  }
`;

export const fragmentServiceIconBox = graphql`
  fragment ServiceIconBox on PRISMIC_ServiceBodyIcon_box {
    type
    primary {
      caption
      title
      subtitle
      text
      color_scheme
      layout
    }
    fields {
      icon_name
      box_title
      box_content
      box_type
      orientation
    }
  }
`;

IconBox.fragments = [fragmentHomepageIconBox, fragmentServiceIconBox];
