import React from 'react';

import { graphql } from 'gatsby';

import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

interface Props {
  className?: string;
  data?: Array<{ item: string; checked: boolean }>;
}

export default function CheckList({ className = '', data = [] }: Props) {
  return (
    <List className={className}>
      {data.map(({ item, checked }) => (
        <ListItem key={item}>
          <ListItemIcon>
            {checked ? (
              <CheckCircleIcon color="primary" />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
}

export const fragmentServiceCheckList = graphql`
  fragment ServiceCheckList on PRISMIC_ServiceBodyCheck_list {
    type
    label
    primary {
      caption
      title
      subtitle
      image
      cta_label
      cta_target
      layout
      color_scheme
    }
    fields {
      item
      checked
    }
  }
`;

export const fragmentHomepageCheckList = graphql`
  fragment HomepageCheckList on PRISMIC_HomepageBodyCheck_list {
    type
    label
    primary {
      caption
      title
      subtitle
      image
      cta_label
      cta_target
      layout
      color_scheme
    }
    fields {
      item
      checked
    }
  }
`;

CheckList.fragments = [fragmentServiceCheckList, fragmentHomepageCheckList];
