import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import styled from 'styled-components';

import {
  compose,
  spacing,
  palette,
  display,
  flexbox,
  borders,
  PaletteProps,
  BordersProps,
  SpacingProps,
} from '@material-ui/system';

import { Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import RichText from '../RichText';
import { MasonryGrid, MasonryCell } from '../Masonry';

import { FixedImage } from '../Trainings/types';

interface Props {
  data?: Array<{
    avatarSharp: FixedImage;
    name: string;
    company: string;
    comment: [{ text: string }];
    note: number | string;
  }>;
}

export default function TestimonialBox({ data = [] }: Props) {
  return (
    <MasonryGrid gridGap={24}>
      {data.map(({ avatarSharp, name, company, comment, note }) => (
        <MasonryCell key={comment[0].text.substring(10)}>
          <IconBox
            bgcolor="common.white"
            borderRadius="borderRadius"
            color="text.primary"
            p={4}
          >
            <StyledRating
              color="primary.main"
              precision={0.5}
              readOnly
              value={typeof note === 'number' ? note : parseInt(note, 10)}
            />
            {comment.map(({ text }, index) => (
              <Comment key={index} text={text} />
            ))}
            <Persona alignItems="center" display="flex">
              <Img fixed={avatarSharp.childImageSharp.fixed} />
              <Name pl={2}>
                <Typography variant="subtitle2">{name}</Typography>
                <Typography variant="body2">{company}</Typography>
              </Name>
            </Persona>
          </IconBox>
        </MasonryCell>
      ))}
    </MasonryGrid>
  );
}

const Persona = styled.div`
  ${compose(
    flexbox,
    display,
    spacing,
  )}
`;

const Comment = styled(RichText)`
  align-self: start;
  ${compose(
    flexbox,
    spacing,
  )}
  p {
    margin: 0;
  }
`;

const Name = styled.div`
  ${spacing}
`;

const IconBox = styled.div<PaletteProps & BordersProps & SpacingProps>`
  ${compose(
    palette,
    borders,
    spacing,
  )}
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  padding: 32px;
  height: 100%;
`;

const StyledRating = styled(Rating)`
  ${palette}
`;

export const fragmentHomepageTestimonialBox = graphql`
  fragment HomepageTestimonialBox on PRISMIC_HomepageBodyTestimonial_box {
    type
    primary {
      caption
      title
      subtitle
      color_scheme
    }
    fields {
      avatar
      avatarSharp {
        childImageSharp {
          fixed(width: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      name
      company
      comment
      note
    }
  }
`;

export const fragmentServiceTestimonialBox = graphql`
  fragment ServiceTestimonialBox on PRISMIC_ServiceBodyTestimonial_box {
    type
    primary {
      caption
      title
      subtitle
      color_scheme
    }
    fields {
      avatar
      avatarSharp {
        childImageSharp {
          fixed(width: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      name
      company
      comment
      note
    }
  }
`;

TestimonialBox.fragments = [
  fragmentHomepageTestimonialBox,
  fragmentServiceTestimonialBox,
];
