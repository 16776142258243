import React from 'react';

import Section from './Section';

import { PrismicSection } from './types';

interface Props {
  data: Array<PrismicSection>;
}

export default function Sections({ data }: Props) {
  return (
    <>
      {data &&
        data.map(({ type, primary, fields }, index) => {
          return (
            <Section
              caption={primary.caption}
              colorScheme={primary.color_scheme}
              divider={
                index < data.length - 1 &&
                data[index + 1].primary.color_scheme !== 'dark'
              }
              fields={fields}
              key={primary.title}
              layout={primary.layout}
              sliceType={type}
              subtitle={primary.subtitle}
              text={primary.text}
              title={primary.title}
            />
          );
        })}
    </>
  );
}
